.space-btw {
    display: flex;
    justify-content: space-between;
}

.textH:hover {
    color: black;
}

.textH2:hover {
    background: #f44335;
    color: grey;
}

.iconH {
    background: transparent;
    border: none;
    transition: .15s all ease-in-out;
}

.iconH:hover {
    cursor: pointer;
    background: rgba(0,0,0,0.3);
}

.userH {
    background: rgba(0,0,0,0.195);
    transition: .1s all ease-in-out;
}

.userH:hover {
    background: rgba(0,0,0,0.35);
}

.btnH:hover {
    background: rgba(0,0,0,0.4);
    cursor: pointer;
    border-radius: 10px;
}

.btnH {
    transition: .2s all ease-in-out;
}

.btnH2:hover {
    background: rgba(0,0,0,0.4);
    cursor: pointer;
    border-radius: 10px;
    color: white;
}

.btnH2 {
    transition: .2s all ease-in-out;
}

.popup-layout {
    padding: 10px;
    font-size: 14px;
    text-align: center;
}

.closeBtn {
    cursor: pointer;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    color: white;
    background: rgba(0,0,0,0.9);
    transition: all 0.25s ease;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    position: absolute;
    right: 0;
    top: 0;
    align-self: flex-end;
    margin-top: -7px;
    margin-right: -7px;
}

.closeBtn:hover {
    background: red;
    color: white
}

::-webkit-scrollbar {
    width: 8px;
     height: 8px;
}

::-webkit-scrollbar {
    width: 8px;
     height: 8px;
}

::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 6px rgb(0, 0, 0);*/
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: gray;
}
